import React from 'react';
import { observable, computed } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { TransactionList } from '@STORES/common/transaction';
import { formatLocaleDate, formatDeclarationValue, formatMoneyValue, userInitials } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

const SHOW_SPECIES = false;

@inject('store')
@withTranslation(['buyer', 'common'])
@observer
class TransactionRow extends React.Component {
    _editFishingCardNum = (evt) => {
        evt.preventDefault();
        this.props.store.setDisplayFishingCardUpdateDialog(true, this.props.transaction);
    };

    _handleSelection = (evt) => {
        this.props.selectRow(this.props.transaction, evt.target.checked);
    };

    _setGroup = () => {
        this.props.store.setDisplayTransactionGroupingDialog(true, [this.props.transaction]);
    };

    render() {
        const { t } = this.props;
        const trx = this.props.transaction;
        const isAdmin = this.props.store.session.info.user.type === 'buyer_admin';

        const total = (trx.adjustedValue / (trx.unit === 'kg' ? 1000 : 1)) * trx.price;
        const species = this.props.store.species.byCode(trx.declarationSpecies);

        let CR = null;
        if (trx.declarationTags && trx.declarationTags.includes('consumption')) {
            CR = <i className="purple">C</i>;
        } else if (trx.declarationTags && trx.declarationTags.includes('restocking')) {
            CR = <i className="darkPurple">R</i>;
        }

        return (
            <tr key={trx.id}>
                {isAdmin ? (
                    <React.Fragment>
                        <td>
                            <input
                                type="checkbox"
                                id={'cb' + trx.id}
                                checked={this.props.selected}
                                onChange={this._handleSelection}
                            />
                            <label htmlFor={'cb' + trx.id} />
                        </td>
                        <td>
                            <span>{t('common:collector')} :</span>
                            {userInitials(trx.buyerFirstName, trx.buyerLastName)}
                        </td>
                        <td>
                            <span>{t('common:boat')} :</span>
                            {trx.declarationBoatName || '-'}
                        </td>
                        <td>
                            <span>{t('common:registration-num')} :</span>
                            {trx.declarationBoatRegistration || '-'}
                        </td>
                    </React.Fragment>
                ) : (
                    <td className="boat">
                        <span>{t('common:boat')} :</span>
                        {trx.declarationBoatName || '-'}
                        {trx.declarationBoatRegistration && ` / ${trx.declarationBoatRegistration}`}
                    </td>
                )}
                <td className="name">
                    <span>{t('common:name')} :</span>
                    {trx.declarationLastName} {trx.declarationFirstName}
                </td>
                <td className="place">
                    <span>{t('buyer:location')} :</span>
                    {trx.declarationZoneName}
                </td>
                <td className="date">
                    <span>{t('common:date')} :</span>
                    {formatLocaleDate(trx.creationDate, 'P p')}
                </td>
                {SHOW_SPECIES && (
                    <td>
                        <span>{t('common:species')} :</span>
                        {species.name}
                    </td>
                )}
                <td className="weight">
                    <span>{t('common:weight')} :</span>
                    {formatDeclarationValue(trx.adjustedValue || trx.declarationValue)}
                </td>
                <td className="CorR">
                    <span>C/R :</span>
                    {CR}
                </td>
                <td className="ticketNumber">
                    <span>{t('buyer:fishing-card')} :</span>
                    {this.props.transaction.canEditFishingCard() ? (
                        <a href="#" onClick={this._editFishingCardNum}>
                            {this.props.transaction.fishingCard}
                        </a>
                    ) : (
                        this.props.transaction.fishingCard
                    )}
                </td>
                {isAdmin && (
                    <td>
                        <span>{t('common:lot')} :</span>
                        {trx.groupName ? (
                            <a href="#" onClick={this._setGroup}>
                                {trx.groupName}
                            </a>
                        ) : (
                            <SVGObject className="tool" objectId="editIcon" onClick={this._setGroup} />
                        )}
                    </td>
                )}
                <td className="price">
                    <span>{t('common:price')} :</span>
                    {formatMoneyValue(trx.price / 100)}/{trx.unit}
                </td>
                {isAdmin && (
                    <td>
                        <span>{t('common:amount')} :</span>
                        <b>
                            <u />
                            {formatMoneyValue(total / 100)}
                        </b>
                    </td>
                )}
            </tr>
        );
    }
}

@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class PastTransactionsSectionContent extends React.Component {
    @observable transactions = [];

    componentDidMount() {
        this.props.store
            .listProcessedTransactions()
            .then((list) => (this.transactions = TransactionList.create(list || [])));
    }

    @computed
    get allSelected() {
        return (
            this.props.selections &&
            this.props.selections.size > 0 &&
            this.props.selections.size === this.transactions.length
        );
    }

    _selectAll = (evt) => {
        const checked = evt.target.checked;
        this.transactions.forEach((trx) => this._selectRow(trx, checked));
    };

    _deselectAll = () => {
        if (!this.props.selections) {
            return;
        }

        this.props.selections.clear();
    };

    _selectRow = (transaction, flag) => {
        if (!this.props.selections) {
            return;
        }

        if (flag) {
            this.props.selections.set(transaction.id, transaction);
        } else {
            this.props.selections.delete(transaction.id);
        }
    };

    render() {
        const { t } = this.props;
        const isAdmin = this.props.store.session.info.user.type === 'buyer_admin';

        const count = (this.props.selections && this.props.selections.size) || this.transactions.length;
        const totalSource =
            this.props.selections && this.props.selections.size > 0
                ? Array.from(this.props.selections.values())
                : this.transactions;
        const totalWeight = totalSource.reduce((acc, trx) => acc + (trx.adjustedValue || trx.declarationValue), 0);

        const transactions = this.transactions.map((trx) => (
            <TransactionRow
                transaction={trx}
                selected={this.props.selections && this.props.selections.has(trx.id)}
                selectRow={this._selectRow}
                key={trx.id}
            />
        ));

        return (
            <React.Fragment>
                <div className="scroll">
                    <table className="withCheckboxes">
                        <thead>
                            <tr>
                                {isAdmin ? (
                                    <React.Fragment>
                                        <th>
                                            <input
                                                type="checkbox"
                                                id="cb-all"
                                                checked={this.allSelected}
                                                onChange={this._selectAll}
                                            />
                                            <label htmlFor="cb-all" />
                                        </th>
                                        <th>{t('common:collector')}</th>
                                        <th>{t('common:boat')}</th>
                                        <th>{t('common:registration-num')}</th>
                                    </React.Fragment>
                                ) : (
                                    <th>{t('common:boat')}</th>
                                )}
                                <th>{t('common:name')}</th>
                                <th>{t('buyer:location')}</th>
                                <th>{t('common:date')}</th>
                                {SHOW_SPECIES && <th>{t('common:species')}</th>}
                                <th>{t('common:weight')}</th>
                                <th>C/R</th>
                                <th>{t('buyer:fishing-card')}</th>
                                {isAdmin && <th>{t('common:lot')}</th>}
                                <th>{t('common:price')}</th>
                                {isAdmin && <th>{t('common:amount')}</th>}
                            </tr>
                        </thead>
                        <tbody>{transactions}</tbody>
                    </table>
                </div>
                <div className="exit">
                    <div>
                        {this.props.selections && (
                            <button disabled={this.props.selections.size === 0} onClick={this._deselectAll}>
                                {t('common:deselect-all')}
                            </button>
                        )}
                    </div>
                    <div>
                        <h3>
                            {this.props.selections && this.props.selections.size > 0
                                ? t('buyer:total-selection', { count })
                                : t('buyer:total-transactions', { count })}{' '}
                            : {formatDeclarationValue(totalWeight)}
                        </h3>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
