import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { InfoArrow } from '@COMPONENTS/common/InfoArrow';
import { SelectorLink } from '@COMPONENTS/common/SelectorLink';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class CollectorSelector extends React.Component {
    componentDidMount() {
        this.props.store.collectors.listAll();
    }

    _addCollector = () => {
        this.props.store.app.setModal(true);
        this.props.store.collectors.setShowCollectorDialog(true);
    };

    render() {
        const collectors = this.props.store.collectors.list.map((collector) => {
            const label = collector.fullName();
            const link = `/collector/${collector.id}`;
            return <SelectorLink label={label} to={link} key={collector.id} />;
        });

        let defaultRoute = null;
        const firstCollector = this.props.store.collectors.list.length > 0 ? this.props.store.collectors.list[0] : null;
        if (firstCollector) {
            const defaultSelection = `/collector/${firstCollector.id}`;
            defaultRoute = <Route exact path="/collector" render={() => <Redirect to={defaultSelection} />} />;
        }

        const { t } = this.props;
        return (
            <div className="list subList show">
                <dl className="show">
                    <div className="burger">
                        <span />
                    </div>
                    <dt>{t('common:collectors')}</dt>
                    {collectors}
                    {defaultRoute}
                </dl>
                <InfoArrow message={t('buyer:collectors-selector.arrow-message')} show={collectors.length === 0} />
                <ul className="toolbar">
                    <li className="plus" onClick={this._addCollector} />
                </ul>
            </div>
        );
    }
}
