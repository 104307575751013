import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';

import { validator } from '@SUPPORT/validator';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class CollectorDialog extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    firstName: { type: 'text', rules: 'required' },
                    lastName: { type: 'text', rules: 'required' },
                    identifier: { type: 'text', rules: 'required' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    _create = (evt) => {
        evt.preventDefault();
        let collectorId = 0;
        this.props.store.collectors
            .createCollector(this.form.values())
            .then((res) => (collectorId = res))
            .then(() => this.props.store.collectors.listAll())
            .then(() => this.props.history.push(`/collector/${collectorId}`))
            .then(() => this._dismiss());
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this.props.store.collectors.setShowCollectorDialog(false);
        this.props.store.app.setModal(false);
        this.form.reset();
    };

    render() {
        const classes = classNames('modal', { show: this.props.store.collectors.showCollectorDialog });
        const { t } = this.props;
        return (
            <div className={classes}>
                <div className="sheet">
                    <div className="title">
                        <h3>{t('buyer:collector-dialog.new-collector')}</h3>
                        <svg>
                            <SVGObject objectId="exitCross" onClick={this._cancel} />
                        </svg>
                    </div>
                    <div className="scroll">
                        <form>
                            <div className="required">
                                <label>{t('common:first-name')} :</label>
                                <input {...this.form.select('firstName').bind()} />
                            </div>
                            <div className="required">
                                <label>{t('common:name')} :</label>
                                <input {...this.form.select('lastName').bind()} />
                            </div>
                            <div>
                                <label>{t('common:identifier')} :</label>
                                <input {...this.form.select('identifier').bind()} />
                            </div>
                        </form>
                    </div>
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._cancel}>{t('common:cancel')}</button>
                            <button onClick={this._create} disabled={!this.form.isValid}>
                                {t('common:create')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
