import React from 'react';
import { withTranslation } from 'react-i18next';

import { PendingTransactionsSectionContent } from './PendingTransactionsSectionContent';

@withTranslation(['buyer', 'common'])
export class PendingTransactionsSection extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="styledList selected">
                <div className="header">
                    <h3>{t('buyer:pending-transactions')}</h3>
                </div>
                <PendingTransactionsSectionContent />
            </div>
        );
    }
}
