import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { InfoArrow } from '@COMPONENTS/common/InfoArrow';
import { SelectorLink } from '@COMPONENTS/common/SelectorLink';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class AdminSelector extends React.Component {
    componentDidMount() {
        this.props.store.admins.listAll();
    }

    _addAdmin = () => {
        // this.props.store.app.setModal(true);
        // this.props.store.collectors.setShowCollectorDialog(true);
    };

    render() {
        const admins = this.props.store.admins.list.map((admin) => {
            const label = admin.fullName();
            const link = `/admin/${admin.id}`;
            return <SelectorLink label={label} to={link} key={admin.id} />;
        });

        let defaultRoute = null;
        const firstAdmin = this.props.store.admins.list.length > 0 ? this.props.store.admins.list[0] : null;
        if (firstAdmin) {
            const defaultSelection = `/admin/${firstAdmin.id}`;
            defaultRoute = <Route exact path="/admin" render={() => <Redirect to={defaultSelection} />} />;
        }

        const { t } = this.props;
        return (
            <div className="list subList show">
                <dl className="show">
                    <div className="burger">
                        <span />
                    </div>
                    <dt>{t('common:admins')}</dt>
                    {admins}
                    {defaultRoute}
                </dl>
                <InfoArrow message={t('buyer:admins-selector.arrow-message')} show={admins.length === 0} />
                <ul className="toolbar">
                    <li className="plus" onClick={this._addAdmin} />
                </ul>
            </div>
        );
    }
}
