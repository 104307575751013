import { types, getRoot, flow } from 'mobx-state-tree';

import { User } from '@STORES/common/user';

import { api } from '@SUPPORT/api';
import { byLastName } from '@SUPPORT/utils';

export const CompanyCollectorsStore = types
    .model('CompanyCollectorsStore', {
        list: types.array(User),
        showCollectorDialog: false
    })
    .actions((self) => {
        const { app, session } = getRoot(self);

        return {
            listAll: flow(function* () {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listBuyerOrgCollectors(info.org.id);
                    self.list = response.data.sort(byLastName) || [];
                } finally {
                    app.setBusy(false);
                }
            }),

            setShowCollectorDialog: (show) => {
                self.showCollectorDialog = show;
            },

            createCollector: flow(function* (collectorInfo) {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    const response = yield api.createCollector(sessionInfo.org.id, collectorInfo);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            updateCollector: flow(function* (collectorId, collectorInfo) {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    yield api.updateCollector(sessionInfo.org.id, collectorId, collectorInfo);
                } finally {
                    app.setBusy(false);
                }
            }),

            listCollectorPrices: flow(function* (collectorId, speciesCode) {
                app.setBusy();
                try {
                    const response = yield api.listSpeciesPrices(collectorId, speciesCode);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            createNewPrice: flow(function* (collectorId, speciesCode, price, unit, currency) {
                app.setBusy();
                try {
                    const response = yield api.createNewPrice(collectorId, speciesCode, price, unit, currency);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
