import { types, getRoot, flow } from 'mobx-state-tree';

import { User } from '@STORES/common/user';

import { api } from '@SUPPORT/api';
import { byLastName } from '@SUPPORT/utils';

export const CompanyAdminsStore = types
    .model('CompanyAdminsStore', {
        list: types.array(User)
    })
    .actions((self) => {
        const { app, session } = getRoot(self);

        return {
            listAll: flow(function* () {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listBuyerOrgAdmins(info.org.id);
                    self.list = response.data.sort(byLastName) || [];
                } finally {
                    app.setBusy(false);
                }
            }),

            updateAdmin: flow(function* (adminId, info) {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    const response = yield api.updateBuyerOrgAdmin(sessionInfo.org.id, adminId, info);
                    session.getSession(true);
                    return response;
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
