import React from 'react';
import { observable, computed } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { PastTransactionsSectionContent } from './PastTransactionsSectionContent';

@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class PastTransactionsSection extends React.Component {
    @observable selections = new Map();

    componentDidMount() {
        this.selections.clear();
    }

    @computed
    get someSelected() {
        return this.selections.size > 0;
    }

    _groupSelected = () => {
        const selectedTransactions = Array.from(this.selections.values());
        this.props.store.setDisplayTransactionGroupingDialog(true, selectedTransactions, () => this.selections.clear());
    };

    render() {
        const { t } = this.props;
        const isAdmin = this.props.store.session.info.user.type === 'buyer_admin';

        return (
            <div className="selected">
                <div className="header">
                    <h3>{t('buyer:past-transactions-section.processed-transactions')}</h3>
                    {isAdmin && (
                        <div className="toolkit">
                            <div>
                                <button onClick={this._groupSelected} disabled={!this.someSelected}>
                                    {t('buyer:group-transactions')}
                                </button>
                                <button className="not-implemented">{t('common:export')}</button>
                                <button className="not-implemented">{t('common:print')}</button>
                            </div>
                        </div>
                    )}
                </div>

                <PastTransactionsSectionContent selections={this.selections} />
            </div>
        );
    }
}
