import { types, getRoot, flow } from 'mobx-state-tree';

import { Species } from '@STORES/common/species';

import { api } from '@SUPPORT/api';
import { byKey } from '@SUPPORT/utils';

export const SpeciesPrice = types
    .model('SpeciesPrice', {
        species: types.reference(Species),
        price: types.number,
        unit: types.string,
        currency: types.string
    })
    .preProcessSnapshot((snapshot) => snapshot && { ...snapshot, unit: snapshot.unit.trim() });

export const TransactionPricesStore = types
    .model('TransactionPricesStore', {
        orgSpecies: types.array(Species),
        species: types.array(SpeciesPrice),
        showSpeciesDialog: false
    })
    .actions((self) => {
        const { app, session } = getRoot(self);

        return {
            setDisplaySpeciesDialog(show) {
                self.showSpeciesDialog = show;
                app.setModal(show);
            },

            addSpecies: flow(function* (species) {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    yield api.createPricingSpecies(info.user.id, species);
                } finally {
                    app.setBusy(false);
                }
            }),

            listAvailablePricingSpecies: flow(function* () {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listAvailablePricingSpecies(info.user.id);
                    return (response.data || []).sort(byKey('name'));
                } finally {
                    app.setBusy(false);
                }
            }),

            listSpecies: flow(function* () {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listPricingSpecies(info.user.id);
                    self.species = (response.data || []).sort(byKey('species'));
                } finally {
                    app.setBusy(false);
                }
            }),

            listPricesForSpecies: flow(function* (species) {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listSpeciesPrices(info.user.id, species);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            createNewPrice: flow(function* (species, price, unit, currency) {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.createNewPrice(info.user.id, species, price, unit, currency);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            setNewPrice(species, priceInfo) {
                const info = self.species.find((info) => info.species.code === priceInfo.species);
                if (info) {
                    info.price = priceInfo.pricePerUnit;
                    info.unit = priceInfo.unit;
                    info.currency = priceInfo.currency;
                }
            }
        };
    });
