import React from 'react';
import ReactForm from 'mobx-react-form';
import { observe } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { validator } from '@SUPPORT/validator';
import { formatLocaleDate, formatPhoneNumber, formatPhoneNumberForStorage } from '@SUPPORT/utils';

import { EmptyContent } from '@COMPONENTS/common/EmptyContent';

@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class AdminSection extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    firstName: { type: 'text', rules: 'required' },
                    lastName: { type: 'text', rules: 'required' },
                    email: { type: 'text', rules: 'required' },
                    mobileNum1: { type: 'text', rules: 'required' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    componentDidMount() {
        this.unobserve = observe(
            this.props.store.admins.list,
            () => {
                this._update();
            },
            true
        );
    }

    componentWillUnmount() {
        this.unobserve();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id != prevProps.match.params.id) {
            this._update();
        }
    }

    _getAdmin() {
        const admins = this.props.store.admins.list || [];
        return admins.find((admin) => {
            return admin.id === parseInt(this.props.match.params.id, 10);
        });
    }

    _update() {
        const admin = this._getAdmin();
        if (admin) {
            this.form.init({
                firstName: admin.firstName,
                lastName: admin.lastName,
                email: admin.email,
                mobileNum1: formatPhoneNumber(admin.mobileNum1)
            });
        }
    }

    _reset = (evt) => {
        evt.preventDefault();
        this._update();
    };

    _save = (evt) => {
        evt.preventDefault();

        const admin = this._getAdmin();
        const values = this.form.values();
        const countryCode = this.props.store.session.info.org.countryCode;
        values.mobileNum1 = formatPhoneNumberForStorage(values.mobileNum1, countryCode);

        this.props.store.admins.updateAdmin(admin.id, values).then(() => {
            admin.update({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                mobileNum1: values.mobileNum1
            });

            values.mobileNum1 = formatPhoneNumber(values.mobileNum1);
            this.form.init(values);
        });
    };

    render() {
        const admin = this._getAdmin();
        if (!admin) {
            return (
                <div className="selected">
                    <EmptyContent />
                </div>
            );
        }

        const { t } = this.props;
        return (
            <div className="selected">
                <div className="header">
                    <h3>
                        {admin ? admin.firstName : '-'}&nbsp;
                        {admin ? admin.lastName : '-'}
                        <span>{t('common:admin')}</span>
                    </h3>
                </div>
                <div className="scroll">
                    <form>
                        <div className="required">
                            <label>{t('common:first-name')} :</label>
                            <input {...this.form.select('firstName').bind()} />
                        </div>
                        <div className="required">
                            <label>{t('common:name')} :</label>
                            <input {...this.form.select('lastName').bind()} />
                        </div>
                        <div className="required">
                            <label>{t('common:email')} :</label>
                            <input {...this.form.select('email').bind()} />
                        </div>
                        <div className="required">
                            <label>{t('common:mobile-number')} :</label>
                            <input {...this.form.select('mobileNum1').bind()} />
                        </div>
                        <div>
                            <label>{t('common:initials')} :</label>
                            <input type="text" value={this.props.store.session.info.user.initials} readOnly />
                        </div>
                        <div>
                            <label>{t('buyer:member-since')} :</label>
                            <input
                                type="text"
                                value={admin ? formatLocaleDate(admin.creationDate, 'P') : '-'}
                                readOnly
                            />
                        </div>
                    </form>
                </div>
                <div className="exit">
                    <div />
                    <div>
                        <button disabled={!this.form.isDirty || this.props.store.app.isBusy} onClick={this._reset}>
                            {t('common:reset')}
                        </button>
                        <button
                            disabled={!this.form.isDirty || !this.form.isValid || this.props.store.app.isBusy}
                            onClick={this._save}
                        >
                            {t('common:save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
