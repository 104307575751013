import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class PendingTransactionRejectionDialog extends React.Component {
    _reject = (evt) => {
        evt.preventDefault();
        this.props.store
            .rejectTransaction(this.props.store.editedTransaction.id)
            .then(() => {
                this.props.store.editedTransaction.setStatus('rejected');
                this._dismiss();
            })
            .catch(() => this._dismiss());
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this.props.store.setDisplayDisagreementDialog(false);
    };

    render() {
        const trx = this.props.store.editedTransaction;
        if (!trx) {
            return null;
        }

        const classes = classNames('modal', { show: this.props.store.showDisagreementDialog });
        const { t } = this.props;
        return (
            <div className={classes}>
                <div className="sheet">
                    <div className="title">
                        <h3>{t('common:correction')}</h3>
                        <SVGObject objectId="exitCross" onClick={this._dismiss} />
                    </div>
                    <div className="scroll">
                        <form className="wide">
                            <div>
                                <p>
                                    {t('buyer:pending-transaction-dialog.not-my-company', {
                                        first: trx.declarationFirstName,
                                        last: trx.declarationLastName
                                    })}
                                </p>
                            </div>
                        </form>
                    </div>{' '}
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._cancel}>{t('common:cancel')}</button>
                            <button onClick={this._reject}>{t('common:reject')}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
