import { types, flow, getRoot, clone } from 'mobx-state-tree';

import { AppStore } from '@STORES/common/app';
import { SessionStore } from '@STORES/common/session';
import { SpeciesStore } from '@STORES/common/species';
import { CompanyInfoStore } from '@STORES/buyer/company.info';
import { CompanyAdminsStore } from '@STORES/buyer/company.admins';
import { Transaction, TransactionList } from '@STORES/common/transaction';
import { CompanyCollectorsStore } from '@STORES/buyer/company.collectors';
import { TransactionPricesStore, SpeciesPrice } from '@STORES/buyer/transactions.price';

import { api } from '@SUPPORT/api';

const BuyerStore = types
    .model('BuyerStore', {
        app: types.optional(AppStore, {}),
        session: types.optional(SessionStore, {}),
        species: types.optional(SpeciesStore, {}),

        companyInfo: types.optional(CompanyInfoStore, {}),
        admins: types.optional(CompanyAdminsStore, {}),
        collectors: types.optional(CompanyCollectorsStore, {}),
        prices: types.optional(TransactionPricesStore, {}),

        showDisagreementDialog: false,
        showValidationDialog: false,
        showFishingCardUpdateDialog: false,
        showTransactionGroupingDialog: false,

        pendingTransactions: TransactionList,

        editedTransaction: types.maybeNull(types.reference(Transaction)),
        editedTransactionPrice: types.maybeNull(SpeciesPrice),
        transactionsToGroup: types.maybeNull(types.array(types.reference(Transaction)))
    })
    .volatile(() => {
        return {
            afterGrouping: null
        };
    })
    .actions((self) => {
        const { app, session } = getRoot(self);
        return {
            loadInitialData: flow(function* () {
                yield self.species.listAll();
                self.app.setReady();
            }),

            setDisplayDisagreementDialog(show, transaction = null) {
                self.showDisagreementDialog = show;
                self.editedTransaction = show ? transaction : null;
                app.setModal(show);
            },

            setDisplayValidationDialog(show, transaction = null, price = null) {
                self.showValidationDialog = show;
                if (show) {
                    self.editedTransaction = transaction;
                    self.editedTransactionPrice = price
                        ? clone(price)
                        : { species: transaction.declarationSpecies, price: 0, unit: 'kg', currency: 'EUR' };
                } else {
                    self.editedTransaction = null;
                    self.editedTransactionPrice = null;
                }
                app.setModal(show);
            },

            setDisplayFishingCardUpdateDialog(show, transaction = null) {
                self.showFishingCardUpdateDialog = show;
                self.editedTransaction = show ? transaction : null;
                app.setModal(show);
            },

            setDisplayTransactionGroupingDialog(show, transactions = null, afterGrouping = null) {
                self.showTransactionGroupingDialog = show;
                self.transactionsToGroup = show ? transactions : null;
                self.afterGrouping = afterGrouping;
                app.setModal(show);
            },

            listPendingTransactions: flow(function* () {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listPendingTransactions(info.user.id);
                    self.pendingTransactions = TransactionList.create(response.data || []);
                } finally {
                    app.setBusy(false);
                }
            }),

            listProcessedTransactions: flow(function* () {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listProcessedTransactions(info.user.id);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            acceptTransaction: flow(function* (transactionId, fishingCardNum, price, value, status = 'accepted') {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    yield api.acceptTransaction(transactionId, info.user.id, fishingCardNum, price, value, status);
                } finally {
                    app.setBusy(false);
                }
            }),

            rejectTransaction: flow(function* (transactionId, price) {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    yield api.rejectTransaction(transactionId, info.user.id, price);
                } finally {
                    app.setBusy(false);
                }
            }),

            groupTransactions: flow(function* (name, transactions) {
                app.setBusy();
                try {
                    yield api.groupTransactions(
                        name,
                        transactions.map((trx) => trx.id)
                    );
                    if (self.afterGrouping) {
                        self.afterGrouping();
                    }
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });

export const store = BuyerStore.create({});
