import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';

import { validator } from '@SUPPORT/validator';
import { CURRENCY_SYMBOL_FOR_CODE, parseFloatingNum } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

const ALLOW_WEIGHT_CHANGE = false;

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class PendingTransactionValidationDialog extends React.Component {
    @observable duplicateError = false;

    constructor(props) {
        super(props);
        const isAdmin = props.store.session.info.user.type === 'buyer_admin';
        this.form = new ReactForm(
            {
                fields: {
                    fishingCardNum: { type: 'text', rules: 'required' },
                    newPrice: { type: 'text', rules: isAdmin ? 'fnumeric|required' : 'fnumeric' },
                    newValue: { type: 'text', rules: 'fnumeric' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    @action
    setDuplicateError(flag = true) {
        this.duplicateError = flag;
    }

    _validate = (evt) => {
        evt.preventDefault();

        let status = 'accepted';
        const values = this.form.values();

        if (values.newPrice === '') {
            values.newPrice = this.props.store.editedTransactionPrice;
        } else if (values.newPrice !== this.props.store.editedTransactionPrice.price) {
            values.newPrice = {
                ...this.props.store.editedTransactionPrice,
                price: parseInt(values.newPrice, 10) * 100
            };
            status = 'updated';
        }

        if (!ALLOW_WEIGHT_CHANGE || values.newValue === '') {
            values.newValue = this.props.store.editedTransaction.declarationValue;
        } else if (values.newValue !== this.props.store.editedTransaction.declarationValue) {
            const multiplier = this.props.store.editedTransactionPrice.unit === 'kg' ? 1000 : 1;
            values.newValue = Math.round(parseFloatingNum(values.newValue) * multiplier);
            status = 'updated';
        }

        this.setDuplicateError(false);
        this.props.store
            .acceptTransaction(
                this.props.store.editedTransaction.id,
                values.fishingCardNum,
                values.newPrice,
                values.newValue,
                status
            )
            .then(() => {
                this.props.store.editedTransaction.update(
                    values.fishingCardNum,
                    values.newPrice,
                    values.newValue,
                    status
                );
                this._dismiss();
            })
            .catch((err) => {
                if (err.code === 409) {
                    this.setDuplicateError();
                }
            });
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this.props.store.setDisplayValidationDialog(false);
        this.form.reset();
    };

    render() {
        const trx = this.props.store.editedTransaction;
        if (!trx) {
            return null;
        }

        const classes = classNames('modal', { show: this.props.store.showValidationDialog });
        const currency = CURRENCY_SYMBOL_FOR_CODE[this.props.store.app.country.currency];
        const price = this.props.store.editedTransactionPrice || {};
        const value = this.props.store.editedTransaction.declarationValue / (price.unit === 'kg' ? 1000 : 1);

        const { t } = this.props;
        return (
            <div className={classes}>
                <div className="sheet">
                    <div className="title">
                        <h3>{t('buyer:pending-transaction-validation-dialog.more-info')}</h3>
                        <SVGObject objectId="exitCross" onClick={this._dismiss} />
                    </div>
                    <div className="scroll">
                        <form>
                            <div>
                                <p style={{ marginBottom: 18 }}>
                                    {t('buyer:pending-transaction-validation-dialog.info1')}
                                    <br />
                                    {t('buyer:pending-transaction-validation-dialog.info2')}
                                </p>
                            </div>
                            <div className="required">
                                <label>{t('buyer:fishing-card')} :</label>
                                <input {...this.form.select('fishingCardNum').bind()} autoFocus />
                            </div>
                            {this.duplicateError && (
                                <div className="error noGap">
                                    <label />
                                    <p>{t('buyer:pending-transaction-validation-dialog.card-num-exists')}</p>
                                </div>
                            )}
                            <div>
                                <label>{t('common:price')} :</label>
                                <input
                                    className="midSize"
                                    {...this.form.select('newPrice').bind({
                                        placeholder: (price.price || 0) / 100
                                    })}
                                />
                                <label>
                                    {currency}/{price.unit}
                                </label>
                            </div>
                            {ALLOW_WEIGHT_CHANGE && (
                                <div>
                                    <label>{t('common:weight')} :</label>
                                    <input
                                        className="midSize"
                                        {...this.form.select('newValue').bind({ placeholder: value })}
                                    />
                                    <label>{price.unit}</label>
                                </div>
                            )}
                        </form>
                    </div>
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._cancel}>{t('common:cancel')}</button>
                            <button onClick={this._validate} disabled={!this.form.isValid}>
                                {t('common:validate')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
