import React from 'react';
import classNames from 'classnames';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export function InfoArrow(props) {
    return (
        <div id="infoArrow" className={classNames({ show: props.show })}>
            <p>{props.message}</p>
            <SVGObject objectId="infoArrow" />
        </div>
    );
}
