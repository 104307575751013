import { types, getRoot, flow } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

import { BuyerOrg } from '@STORES/common/buyer-org.js';

export const CompanyInfoStore = types
    .model('CompanyInfoStore', {
        info: types.maybeNull(BuyerOrg)
    })
    .actions((self) => {
        const { app, session } = getRoot(self);

        return {
            getInfo: flow(function* () {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    const response = yield api.getBuyerOrgInfo(sessionInfo.org.id);
                    self.info = response.data || {};
                    return self.info;
                } finally {
                    app.setBusy(false);
                }
            }),

            updateInfo: flow(function* (orgInfo) {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    return yield api.updateBuyerOrgInfo(sessionInfo.org.id, orgInfo);
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
