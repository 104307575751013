import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { CollectorProfileSubSection } from './CollectorProfileSubSection';
import { CollectorPricingSubSection } from './CollectorPricingSubSection';

import { NavigationLink } from '@COMPONENTS/common/NavigationLink';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class CollectorSection extends React.Component {
    render() {
        const collectors = this.props.store.collectors.list || [];
        const collector = collectors.find((collector) => collector.id === parseInt(this.props.match.params.id, 10));
        if (!collector) {
            return null;
        }

        const { t } = this.props;
        return (
            <div className="selected">
                <div className="header noBottom">
                    <h3>
                        {collector.fullName(false)}
                        <span>{t('common:collector')}</span>
                    </h3>
                    <nav>
                        <ul>
                            <NavigationLink to={`/collector/${collector.id}/profile`} label="Profil" tag="li" />
                            <NavigationLink to={`/collector/${collector.id}/prices`} label="Prix" tag="li" />
                        </ul>
                    </nav>
                </div>
                <div className="panels">
                    <Route
                        path="/collector/:id/profile"
                        render={() => <CollectorProfileSubSection user={collector} />}
                    />
                    <Route
                        path="/collector/:id/prices"
                        render={() => <CollectorPricingSubSection user={collector} />}
                    />

                    <Route
                        exact
                        path="/collector/:id"
                        render={() => <Redirect to={`/collector/${collector.id}/profile`} />}
                    />
                </div>
            </div>
        );
    }
}
