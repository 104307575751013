import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import ReactForm from 'mobx-react-form';

import { validator } from '@SUPPORT/validator';
import { CURRENCY_SYMBOL_FOR_CODE, formatLocaleDate, formatMoneyValue, parseFloatingNum } from '@SUPPORT/utils';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class CollectorPricingSubSection extends React.Component {
    @observable prices = [];

    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    price: { type: 'text', rules: 'required|regex:/\\d*[.,]?\\d*?/', placeholder: '50.26' },
                    unit: { rules: 'required', value: 'kg' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    componentDidMount() {
        this._update();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.code != prevProps.match.params.code) {
            this._update();
        }
    }

    _update() {
        this.props.store.collectors
            .listCollectorPrices(this.props.match.params.id, 'EL1')
            .then((prices) => (this.prices = prices || []));
    }

    _newPrice = (evt) => {
        evt.preventDefault();

        const collectorId = this.props.match.params.id;
        const newPrice = parseFloatingNum(this.form.select('price').value.replace(/,/, '.')) * 100;
        const unit = this.form.select('unit').value;
        const currency = this.props.store.app.country.currency;

        this.props.store.collectors.createNewPrice(collectorId, 'EL1', newPrice, unit, currency).then((newPrice) => {
            this.prices.unshift(newPrice);
            this.form.reset();

            this.props.store.prices.setNewPrice(this.props.match.params.code, newPrice, unit, currency);
        });
    };

    render() {
        const { t } = this.props;

        const prices = [];
        this.prices.forEach((price, i) => {
            let diff, diffLabel;
            const prevPrice = i < this.prices.length - 1 ? this.prices[i + 1] : null;
            if (prevPrice) {
                diff = price.pricePerUnit - prevPrice.pricePerUnit;
                diffLabel = `${formatMoneyValue(diff / 100)}/${price.unit}`;
            }

            const diffClasses = diff && diff < 0 ? 'codeRed' : null;

            prices.push(
                <tr key={price.creationDate}>
                    <td>
                        <span>{t('common:date')} :</span>
                        {formatLocaleDate(price.creationDate, 'P p')}
                    </td>
                    <td>
                        <span>{t('buyer:new-price')} :</span>
                        <b>
                            {formatMoneyValue(price.pricePerUnit / 100)}/{price.unit}
                        </b>
                    </td>
                    <td className={diffClasses}>
                        <span>{t('common:difference')} :</span>
                        {diffLabel || '-'}
                    </td>
                </tr>
            );
        });

        const currency = CURRENCY_SYMBOL_FOR_CODE[this.props.store.app.country.currency];

        return (
            <div className="panel">
                <div className="scroll">
                    <form>
                        <div className="full aligned">
                            <input type="text" className="short" {...this.form.select('price').bind()} />
                            <select {...this.form.select('unit').bind()}>
                                <option value="kg">{currency}/kg</option>
                                <option value="">{currency}/g</option>
                            </select>
                            <button
                                disabled={!this.form.isDirty || !this.form.isValid || this.props.store.app.isBusy}
                                onClick={this._newPrice}
                            >
                                {t('common:set')}
                            </button>
                        </div>
                    </form>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('common:date')}</th>
                                <th>{t('common:price')}</th>
                                <th>{t('common:difference')}</th>
                            </tr>
                        </thead>
                        <tbody>{prices}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}
