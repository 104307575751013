import React from 'react';
import classNames from 'classnames';
import { observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';

import { formatLocaleDate, formatDeclarationValue, formatMoneyValue } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
class TransactionRow extends React.Component {
    _jumpToSetPrice = (evt) => {
        evt.preventDefault();
        this.props.history.push('/price');
    };

    _accept = (evt) => {
        evt.preventDefault();
        this.props.store.setDisplayValidationDialog(true, this.props.transaction, this.props.price);
    };

    _disagree = (evt) => {
        evt.preventDefault();
        this.props.store.setDisplayDisagreementDialog(true, this.props.transaction);
    };

    _select = (evt) => {
        if (this.props.transaction === 'rejected') {
            return;
        }

        this.props.select(evt.target.checked, this.props.transaction);
    };

    render() {
        const trx = this.props.transaction;

        const isAdmin = this.props.store.session.info.user.type === 'buyer_admin';
        const isApproved = trx.status === 'accepted' || trx.status === 'updated';
        const isRejected = trx.status === 'rejected';
        const isProcessed = isApproved || isRejected;

        const price = trx.price || (this.props.price ? this.props.price.price : 0);

        const acceptClasses = classNames('accept', 'check', { disabled: isProcessed || (!price && !isAdmin) });
        const disagreeClasses = classNames('disagree', 'cross', { disabled: isProcessed || (!price && !isAdmin) });
        const classes = classNames({ codeGreen: isApproved, codeRed: isRejected, codeGray: !isProcessed });

        const { t } = this.props;

        let CR = null;
        if (trx.declarationTags) {
            if (trx.declarationTags.includes('consumption')) {
                CR = t('common:consumption');
            } else if (trx.declarationTags.includes('restocking')) {
                CR = t('common:restocking');
            }
        }

        let boatInfo = trx.declarationBoatName || '';
        if (trx.declarationBoatRegistration) {
            boatInfo += `${boatInfo ? ' / ' : ''}${trx.declarationBoatRegistration}`;
        }

        const unitPrice = formatMoneyValue(price / 100);
        const unitPriceUnit = this.props.price ? this.props.price.unit : 'kg';
        const transactionPrice = formatMoneyValue(((trx.adjustedValue || trx.declarationValue) / 1000) * (price / 100));

        return (
            <li className={classes}>
                <header>
                    <h2>
                        <input
                            type="checkbox"
                            id={`check-${trx.id}`}
                            onChange={this._select}
                            checked={this.props.isSelected}
                        />
                        <label htmlFor={`check-${trx.id}`} style={{ margin: 1 }} />
                        <span>
                            {boatInfo}
                            {boatInfo ? ' - ' : ''}
                            {trx.declarationLastName} {trx.declarationFirstName} ({trx.declarationIdentifier})
                        </span>
                    </h2>
                    <span style={{ display: 'flex' }}>
                        <SVGObject
                            className={disagreeClasses}
                            objectId="noCrossCircleIco"
                            onClick={price || isAdmin ? this._disagree : null}
                        />
                        <SVGObject
                            className={acceptClasses}
                            objectId="checkCircleIco"
                            onClick={price || isAdmin ? this._accept : null}
                        />
                    </span>
                </header>
                <figure>
                    <span className="whenWhere">
                        <p>
                            <span className="date">{formatLocaleDate(trx.creationDate, 'P - p')}</span>
                        </p>
                        <p>{trx.declarationZoneName}</p>
                    </span>
                    <span className="what">
                        <ul className="table TwoCols">
                            <li>
                                <p>
                                    {t('common:weight')} :{' '}
                                    {formatDeclarationValue(trx.adjustedValue || trx.declarationValue)}
                                </p>
                                <p>
                                    {t('buyer:fishing-card')} : {trx.fishingCard || '-'}
                                </p>
                            </li>
                            <li>
                                <p>{price ? `${transactionPrice} (${unitPrice}/${unitPriceUnit})` : '-'}</p>
                                <p>{CR}</p>
                            </li>
                        </ul>
                    </span>
                </figure>
            </li>
        );
    }
}

@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class PendingTransactionsSectionContent extends React.Component {
    @observable selection = new Map();
    _refreshTicker = null;

    componentDidMount() {
        this.props.store.prices.listSpecies().then(this.refreshTransactionsList);
        this._refreshTicker = setInterval(this.refreshTransactionsList, 60000);
    }

    componentDidUpdate() {
        // Auto-deselect rejected transactions
        this.props.store.pendingTransactions.forEach((trx) => {
            if (this.selection.has(trx.id) && trx.status === 'rejected') {
                this.selection.delete(trx.id);
            }
        });
    }

    componentWillUnmount() {
        if (this._refreshTicker) {
            clearInterval(this._refreshTicker);
            this._refreshTicker = null;
        }
    }

    refreshTransactionsList = () => {
        this.props.store.listPendingTransactions().then((transactions) => {
            // Synchronize selection: remove selected processed transactions
            Array.from(this.selection.values()).forEach((selectedTrx) => {
                const fromList = transactions.find((trx) => trx.id === selectedTrx.id);
                if (!fromList) {
                    this.selection.delete(selectedTrx.id);
                }
            });
        });
    };

    selectTransaction = (selected, transaction) => {
        if (selected) {
            this.selection.set(transaction.id, transaction);
        } else {
            this.selection.delete(transaction.id);
        }
    };

    deselectAll = () => {
        this.selection.clear();
    };

    render() {
        const { t } = this.props;
        const isAdmin = this.props.store.session.info.user.type === 'buyer_admin';

        let totalWeight = 0;
        const transactionRows = this.props.store.pendingTransactions.map((trx) => {
            const isSelected = this.selection.has(trx.id);
            const price = this.props.store.prices.species.find((prc) => prc.species.code === trx.declarationSpecies);
            const weight = trx.adjustedValue || trx.declarationValue;
            totalWeight = totalWeight + weight;

            return (
                <TransactionRow
                    transaction={trx}
                    price={price}
                    isAdmin={isAdmin}
                    key={trx.id}
                    isSelected={isSelected}
                    select={this.selectTransaction}
                />
            );
        });

        const count = this.selection.size || this.props.store.pendingTransactions.length;
        const totalSource =
            this.selection.size > 0 ? Array.from(this.selection.values()) : this.props.store.pendingTransactions;
        const total = totalSource.reduce((t, trx) => t + (trx.adjustedValue || trx.declarationValue), 0);

        return (
            <React.Fragment>
                <div className="scroll">
                    <ul>{transactionRows}</ul>
                </div>
                <div className="exit">
                    <div>
                        <button disabled={this.selection.size === 0} onClick={this.deselectAll}>
                            {t('common:deselect-all')}
                        </button>
                    </div>
                    <div>
                        <h3>
                            {this.selection.size > 0
                                ? t('buyer:total-selection', { count })
                                : t('buyer:total-transactions', { count })}{' '}
                            : {formatDeclarationValue(total)}
                        </h3>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
