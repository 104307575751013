import React from 'react';
import ReactForm from 'mobx-react-form';
import { observe } from 'mobx';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';

import { validator } from '@SUPPORT/validator';
import { userInitials, formatLocaleDate, formatPhoneNumber, formatPhoneNumberForStorage } from '@SUPPORT/utils';

import { EmptyContent } from '@COMPONENTS/common/EmptyContent';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class CollectorProfileSubSection extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    firstName: { type: 'text', rules: 'required' },
                    lastName: { type: 'text', rules: 'required' },
                    identifier: { type: 'text', rules: 'required' },
                    email: { type: 'text', rules: 'required' },
                    mobileNum1: { type: 'text', rules: 'required' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    componentDidMount() {
        this.unobserve = observe(
            this.props.store.collectors.list,
            () => {
                this._update();
            },
            true
        );
    }

    componentWillUnmount() {
        this.unobserve();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id != prevProps.match.params.id) {
            this._update();
        }
    }

    _getCollector() {
        const collectors = this.props.store.collectors.list || [];
        return collectors.find((collector) => collector.id === parseInt(this.props.match.params.id, 10));
    }

    _update() {
        const collector = this._getCollector();
        if (collector) {
            this.form.init({
                firstName: collector.firstName,
                lastName: collector.lastName,
                identifier: collector.identifier,
                email: collector.email,
                mobileNum1: formatPhoneNumber(collector.mobileNum1)
            });
        }
    }

    _reset = (evt) => {
        evt.preventDefault();
        this._update();
    };

    _save = (evt) => {
        evt.preventDefault();

        const collector = this._getCollector();
        const values = this.form.values();
        const countryCode = this.props.store.session.info.org.countryCode;
        values.mobileNum1 = formatPhoneNumberForStorage(values.mobileNum1, countryCode);

        this.props.store.collectors.updateCollector(collector.id, values).then(() => {
            collector.update({
                firstName: values.firstName,
                lastName: values.lastName,
                identifier: values.identifier,
                email: values.email,
                mobileNum1: values.mobileNum1
            });

            values.mobileNum1 = formatPhoneNumber(values.mobileNum1);
            this.form.init(values);
        });
    };

    render() {
        const collector = this._getCollector();
        if (!collector) {
            return (
                <div className="selected">
                    <EmptyContent />
                </div>
            );
        }

        const { t } = this.props;
        return (
            <div className="panel">
                <div className="scroll">
                    <form>
                        <div className="required">
                            <label>{t('common:first-name')} :</label>
                            <input {...this.form.select('firstName').bind()} />
                        </div>
                        <div className="required">
                            <label>{t('common:name')} :</label>
                            <input {...this.form.select('lastName').bind()} />
                        </div>
                        <div className="required">
                            <label>{t('common:identifier')} :</label>
                            <input {...this.form.select('identifier').bind()} />
                        </div>
                        <div>
                            <label>{t('common:email')} :</label>
                            <input {...this.form.select('email').bind()} />
                        </div>
                        <div>
                            <label>{t('common:mobile-number')} :</label>
                            <input {...this.form.select('mobileNum1').bind()} />
                        </div>
                        <div>
                            <label>{t('common:initials')} :</label>
                            <p>
                                {userInitials(this.form.select('firstName').value, this.form.select('lastName').value)}
                            </p>
                        </div>
                        <div className="aligned full">
                            <label>{t('buyer:member-since')}</label>
                            <p>{formatLocaleDate(collector.creationDate, 'P')}</p>
                        </div>
                    </form>
                </div>
                <div className="exit">
                    <div />
                    <div>
                        <button disabled={!this.form.isDirty || this.props.store.app.isBusy} onClick={this._reset}>
                            {t('common:reset')}
                        </button>
                        <button
                            disabled={!this.form.isDirty || !this.form.isValid || this.props.store.app.isBusy}
                            onClick={this._save}
                        >
                            {t('common:save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
