import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { observable, action } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';

import { validator } from '@SUPPORT/validator';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class TransactionGroupingDialog extends React.Component {
    @observable updating = false;

    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    name: { type: 'text', rules: 'required' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    @action
    setUpdating(updating = true) {
        this.updating = updating;
    }

    _group = (evt) => {
        evt.preventDefault();
        const name = this.form.select('name').value;

        this.setUpdating(true);
        this.props.store
            .groupTransactions(name, this.props.store.transactionsToGroup)
            .then(() => {
                this.props.store.transactionsToGroup.forEach((trx) => trx.setGroup(name));
                this.setUpdating(false);
                this._dismiss();
            })
            .catch(() => this.setUpdating(false));
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this.props.store.setDisplayTransactionGroupingDialog(false);
        this.form.reset();
    };

    render() {
        const transactions = this.props.store.transactionsToGroup || [];
        const isSingle = transactions.length === 1;
        const classes = classNames('modal', { show: this.props.store.showTransactionGroupingDialog });
        const { t } = this.props;

        return (
            <div className={classes}>
                <div className="sheet">
                    <div className="title">
                        <h3>{t('buyer:grouping-dialog.title', { count: transactions.length })}</h3>
                        <SVGObject objectId="exitCross" onClick={this._dismiss} />
                    </div>
                    <div className="scroll">
                        <form onSubmit={this._group}>
                            <div>
                                <p>{t('buyer:grouping-dialog.message')}</p>
                            </div>
                            <div className="required">
                                <label>{t('buyer:grouping-dialog.code-name')} :</label>
                                <input {...this.form.select('name').bind()} />
                            </div>
                        </form>
                    </div>
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._cancel}>{t('common:cancel')}</button>
                            <button onClick={this._group} disabled={!this.form.isValid || this.updating}>
                                {isSingle ? t('common:associate') : t('common:group')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
