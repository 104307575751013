import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { Header } from '@COMPONENTS/common/Header';
import { Copyright } from '@COMPONENTS/common/Copyright';
import { ModalBackground } from '@COMPONENTS/common/ModalBackground';

import { InfoSection } from './company/InfoSection';
import { AdminSelector } from './company/AdminSelector';
import { AdminSection } from './company/AdminSection';
import { CollectorSelector } from './company/CollectorSelector';
import { CollectorSection } from './company/CollectorSection';
import { CollectorDialog } from './company/CollectorDialog';

import { PendingTransactionRejectionDialog } from './transactions/PendingTransactionRejectionDialog';
import { PendingTransactionValidationDialog } from './transactions/PendingTransactionValidationDialog';
import { TransactionGroupingDialog } from './transactions/TransactionGroupingDialog';
import { FishingCardUpdateDialog } from './transactions/FishingCardUpdateDialog';
import { PendingTransactionsSection } from './transactions/PendingTransactionsSection';
import { PendingTransactionsSectionContent } from './transactions/PendingTransactionsSectionContent';
// import TransactionFiltersSelector from './transactions/TransactionFiltersSelector';
import { PastTransactionsSection } from './transactions/PastTransactionsSection';
import { PastTransactionsSectionContent } from './transactions/PastTransactionsSectionContent';

import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { ErrorBoundary } from '@COMPONENTS/common/ErrorBoundary';

@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class BuyerRoot extends React.Component {
    componentDidMount() {
        this.props.store.session.getSession().then(this.props.store.loadInitialData);
    }

    render() {
        if (this.props.store.session.info === null) {
            return null;
        }

        const isAdmin = this.props.store.session.info.user.type === 'buyer_admin';
        const { t } = this.props;

        return (
            <ErrorBoundary>
                <Header key="header" />

                {this.props.store.app.isReady && (
                    <div id="content">
                        {isAdmin && (
                            <React.Fragment>
                                <div className="list">
                                    <dl className="show">
                                        <div className="burger">
                                            <span />
                                        </div>
                                        <h6>{t('common:info')}</h6>
                                        <dt key="header">{t('common:company')}</dt>
                                        <SelectorLink label={t('common:info')} to="/info" key="info" />
                                        <SelectorLink label={t('common:admins')} to="/admin" key="admin" />
                                        <SelectorLink label={t('common:collectors')} to="/collector" key="collector" />
                                        <dt>Transactions</dt>
                                        <SelectorLink
                                            label={t('buyer:pending-transactions')}
                                            to="/transactions/pending"
                                        />
                                        <SelectorLink
                                            label={t('buyer:previous-transactions')}
                                            to="/transactions/processed"
                                        />
                                    </dl>
                                    <ul className="toolbar" />
                                </div>

                                <Route path="/admin" render={() => <AdminSelector baseUrl="/admin" />} />
                                <Route path="/collector" render={() => <CollectorSelector baseUrl="/collector" />} />
                            </React.Fragment>
                        )}

                        <div className="listResult selected">
                            <div className="contentArea">
                                {isAdmin ? (
                                    <React.Fragment>
                                        <Route path="/info" component={InfoSection} />
                                        <Route path="/admin/:id" component={AdminSection} />
                                        <Route path="/collector/:id" component={CollectorSection} />
                                        <Route path="/transactions/pending" component={PendingTransactionsSection} />
                                        <Route path="/transactions/processed" component={PastTransactionsSection} />
                                    </React.Fragment>
                                ) : (
                                    <div className="selected">
                                        <div className="header noBottom">
                                            <h3>Transactions</h3>
                                            <nav>
                                                <ul>
                                                    <SelectorLink
                                                        tag="li"
                                                        label={t('buyer:pending')}
                                                        to="/transactions/pending"
                                                    />
                                                    <SelectorLink
                                                        tag="li"
                                                        label={t('buyer:previous')}
                                                        to="/transactions/processed"
                                                    />
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="panels">
                                            <div className="panel styledList">
                                                <Route
                                                    path="/transactions/pending"
                                                    component={PendingTransactionsSectionContent}
                                                />
                                                <Route
                                                    path="/transactions/processed"
                                                    component={PastTransactionsSectionContent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Copyright key="copyright" />
                        </div>

                        <Route exact path="/" render={() => <Redirect to="/transactions/pending" />} />
                    </div>
                )}

                <ModalBackground display={this.props.store.app.inModal} />
                <CollectorDialog />
                <PendingTransactionRejectionDialog />
                <PendingTransactionValidationDialog />
                <FishingCardUpdateDialog />
                <TransactionGroupingDialog />
            </ErrorBoundary>
        );
    }
}

// <Route path="/transactions/past" component={TransactionFiltersSelector} />
