import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { observable, action } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';

import { validator } from '@SUPPORT/validator';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class FishingCardUpdateDialog extends React.Component {
    @observable duplicateError = false;
    @observable updating = false;

    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    fishingCardNum: { type: 'text', rules: 'required' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    @action
    setDuplicateError(flag = true) {
        this.duplicateError = flag;
    }

    @action
    setUpdating(updating = true) {
        this.updating = updating;
    }

    _update = (evt) => {
        evt.preventDefault();
        const num = this.form.select('fishingCardNum').value;

        this.setDuplicateError(false);
        this.props.store.editedTransaction
            .updateFishingCard(num)
            .then(this._dismiss)
            .catch((err) => {
                if (err.code === 409) {
                    this.setDuplicateError();
                }
            });
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this.props.store.setDisplayFishingCardUpdateDialog(false);
        this.form.reset();
    };

    render() {
        const trx = this.props.store.editedTransaction;
        if (!trx) {
            return null;
        }

        const classes = classNames('modal', { show: this.props.store.showFishingCardUpdateDialog });
        const { t } = this.props;
        return (
            <div className={classes}>
                <div className="sheet">
                    <div className="title">
                        <h3>Changer le numéro de fiche de pêche</h3>
                        <SVGObject objectId="exitCross" onClick={this._dismiss} />
                    </div>
                    <div className="scroll">
                        <form>
                            <div>
                                <p>Vous désirez changer le numéro de pêche {trx.fishingCard}.</p>
                            </div>
                            <div>
                                <label>Nouveau numéro :</label>
                                <input {...this.form.select('fishingCardNum').bind()} />
                            </div>
                            {this.duplicateError && (
                                <div className="error noGap">
                                    <label />
                                    <p>Ce numéro de pêche existe déjà.</p>
                                </div>
                            )}
                        </form>
                    </div>
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._cancel}>{t('common:cancel')}</button>
                            <button onClick={this._update} disabled={!this.form.isValid || this.updating}>
                                {t('common:validate')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
