import React from 'react';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { validator } from '@SUPPORT/validator';

@inject('store')
@withTranslation(['buyer', 'common'])
@observer
export class InfoSection extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    name: { type: 'text', rules: 'required' },
                    ownerName: { type: 'text' },
                    ownerPhone: { type: 'text' },
                    ownerEmail: { type: 'text' },
                    companyEmail: { type: 'text', rules: 'required' },
                    phoneNumber: { type: 'text', rules: 'required' },
                    address1: { type: 'text' },
                    address2: { type: 'text' },
                    zipCode: { type: 'text' },
                    city: { type: 'text' },
                    country: { type: 'text' },
                    companyId: { type: 'text' },
                    vatNum: { type: 'text' },
                    accreditation: { type: 'text' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    componentDidMount() {
        this._initFromBackend();
    }

    _initFromBackend() {
        this.props.store.companyInfo.getInfo().then((info) => {
            this.form.init(info);
        });
    }

    _reset = (evt) => {
        evt.preventDefault();
        this._initFromBackend();
    };

    _save = (evt) => {
        evt.preventDefault();
        this.props.store.companyInfo.updateInfo(this.form.values()).then(() => {
            this.form.init(this.form.values());
        });
    };

    render() {
        const { t } = this.props;
        return (
            <div className="selected">
                <div className="header">
                    <h3>
                        {this.form.select('name').value || '-'}
                        <span>{t('common:company')}</span>
                    </h3>
                </div>
                <div className="scroll">
                    <form>
                        <div className="required full">
                            <label>{t('common:company-name')} :</label>
                            <input {...this.form.select('name').bind()} />
                        </div>
                        <div className="required">
                            <label>{t('common:owner')} :</label>
                            <input {...this.form.select('ownerName').bind()} />
                        </div>
                        <div className="required">
                            <label>{t('buyer:info-section.owners-phone')} :</label>
                            <input {...this.form.select('ownerPhone').bind()} />
                        </div>
                        <div className="required">
                            <label>{t('buyer:info-section.owners-email')} :</label>
                            <input {...this.form.select('ownerEmail').bind()} />
                        </div>
                        <h4>Contact</h4>
                        <div className="required">
                            <label>{t('buyer:info-section.company-email')} :</label>
                            <input {...this.form.select('companyEmail').bind()} />
                        </div>
                        <div className="required">
                            <label>{t('buyer:info-section.main-phone-number')} :</label>
                            <input {...this.form.select('phoneNumber').bind()} />
                        </div>
                        <h4>{t('common:postal-address')}</h4>
                        <div>
                            <label>{t('common:street')} :</label>
                            <input {...this.form.select('address1').bind()} />
                        </div>
                        <div>
                            <label>
                                {t('common:address')} ({t('common:more-info')}) :
                            </label>
                            <input {...this.form.select('address2').bind()} />
                        </div>
                        <div>
                            <label>{t('common:zipcode')} :</label>
                            <input {...this.form.select('zipCode').bind()} />
                        </div>
                        <div>
                            <label>{t('common:city')} :</label>
                            <input {...this.form.select('city').bind()} />
                        </div>
                        <div>
                            <label>{t('common:country')} :</label>
                            <input {...this.form.select('country').bind()} />
                        </div>
                        <h4>Info</h4>
                        <div>
                            <label>{t('buyer:info-section.company-id')} :</label>
                            <input {...this.form.select('companyId').bind()} />
                        </div>
                        <div>
                            <label>{t('buyer:info-section.vat-number')} :</label>
                            <input {...this.form.select('vatNum').bind()} />
                        </div>
                        <div>
                            <label>{t('buyer:info-section.accreditation')} :</label>
                            <input {...this.form.select('accreditation').bind()} />
                        </div>
                    </form>
                </div>
                <div className="exit">
                    <div />
                    <div>
                        <button disabled={!this.form.isDirty || this.props.store.app.isBusy} onClick={this._reset}>
                            {t('common:reset')}
                        </button>
                        <button
                            disabled={!this.form.isDirty || !this.form.isValid || this.props.store.app.isBusy}
                            onClick={this._save}
                        >
                            {t('common:save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
